define("mgw/components/edit-users-filters", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    intl: Ember.inject.service(),
    // Input
    roleFilterOptions: Ember.computed.alias('data.roleOptions'),
    // Internal
    searchStr: '',
    searchProjectStr: '',
    roleFilterValue: -1,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
    },
    setup: function setup() {
      this.set('searchStr', this.get('data.userFilter') || '');
      this.set('searchProjectStr', this.get('data.projectFilter') || '');
      this.set('roleFilterValue', this.get('data.roleFilter') || -1);
    },
    actions: {
      submit: function submit() {
        this.get('delegate').send(this.get('callback'), this.get('searchStr'), this.get('searchProjectStr'), this.get('roleFilterValue'));
        this.send('close');
        return false;
      },
      clearFilters: function clearFilters() {
        this.set('searchStr', '');
        this.set('searchProjectsStr', '');
        this.set('roleFilterValue', -1);
        return false;
      },
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
        return false;
      },
      searchProjectsChanged: function searchProjectsChanged(value) {
        this.set('searchProjectsStr', value);
        return false;
      },
      changeRoleFilter: function changeRoleFilter(selected) {
        this.set('roleFilterValue', selected.value);
        return false;
      }
    }
  });

  _exports.default = _default;
});