define("mgw/controllers/organizations", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    popoverservice: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    searchStr: '',
    dataMap: null,
    cache: null,
    users: Ember.computed('model.users', function () {
      return this.get('model.users').toArray().sort(function (a, b) {
        var aValue = a.get('displayName');
        if (aValue[0] == '@') aValue = aValue.slice(1);
        var bValue = b.get('displayName');
        if (bValue[0] == '@') bValue = bValue.slice(1);
        return aValue.localeCompare(bValue);
      });
    }),
    organizations: Ember.computed('model.organizations', function () {
      return this.get('model.organizations');
    }),
    selectedItem: null,
    columnSettingsVersion: 1,
    columnSettingsKey: Ember.computed('currentUser.activeCustomer', function () {
      return 'organizationsGridColumns-' + this.get('currentUser.activeCustomer.id');
    }),
    items: Ember.computed('organizations', 'users', function () {
      var itemsMap = {};
      var items = [];
      this.get('organizations').forEach(function (org) {
        var item = {
          id: org.id,
          organization: org,
          users: []
        };
        items.push(item);
        itemsMap[org.id] = item;
      });
      this.get('users').forEach(function (user) {
        if (user.get('userOrganization.id') && itemsMap[user.get('userOrganization.id')]) {
          itemsMap[user.get('userOrganization.id')].users.push(user);
        }
      });
      return items;
    }),
    columns: Ember.computed(function () {
      var defaultColumns = [{
        id: "name",
        field: "name",
        name: this.intl.t('name'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "number",
        field: "number",
        name: this.intl.t('number'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "country",
        field: "country",
        name: this.intl.t('country'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "externalId",
        field: "externalId",
        name: this.intl.t('externalId'),
        width: 250,
        sortable: true,
        resizable: true
      }, {
        id: "users",
        field: "users",
        name: this.intl.t('users'),
        width: 100,
        sortable: true,
        resizable: true
      }];
      var storedColumns = this.get('settings.' + this.get('columnSettingsKey'));

      if (storedColumns && this.columnSettingsVersion == this.get('settings.' + this.get('columnSettingsKey') + 'Version')) {
        // we need to overwrite the formatter here since usersettings cannot store functions 
        storedColumns.forEach(function (column) {
          var result = defaultColumns.findBy('id', column.id);

          if (result) {
            // if does not exist in default columns, the project may been deleted, so we dont keep it
            column.formatter = result.formatter;
          }
        });
        return storedColumns;
      } else {
        this.set('settings.' + this.get('columnSettingsKey'), null);
        this.set('settings.' + this.get('columnSettingsKey') + 'SortByCol', null);
        return defaultColumns;
      }
    }),
    actions: {
      searchChanged: function searchChanged(value) {
        this.set('searchStr', value);
      },
      didSelectItem: function didSelectItem(item) {
        this.set('selectedItem', item);
      },
      changeCustomer: function changeCustomer(customer) {
        this.get('currentUser').changeActiveCustomer(customer);
        this.send('reload');
        return false;
      },
      editOrganization: function editOrganization(item) {
        var itemToEdit = item ? item : this.get('selectedItem');
        if (!itemToEdit) return;
        this.get('popoverservice').openPopover({
          name: 'edit-user-organization',
          delegate: this,
          callback: 'organizationEdited',
          data: {
            organization: itemToEdit.organization,
            users: itemToEdit.users,
            organizations: this.get('organizations')
          },
          popoverClass: 'popover--floating'
        });
      },
      addOrganization: function addOrganization() {
        var userOrg = this.store.createRecord('userOrganization', {
          customer: this.get('currentUser.activeCustomer')
        });
        this.send('editOrganization', userOrg);
      },
      organizationEdited: function organizationEdited(newOrDeleted) {
        if (newOrDeleted) {
          this.send('reload');
        } else {
          this.send('refreshCache');
        }
      },
      refreshCache: function refreshCache() {
        this.set('cache', new Date());
      },
      moreBtn: function moreBtn(sender) {
        var items = [{
          name: this.intl.t('addOrganization'),
          value: 'addOrganization',
          icon: 'icon--plus'
        }];
        this.get('popoverservice').openPopover({
          name: 'select-item',
          data: items,
          callback: 'selectedMoreBtnOption',
          delegate: this,
          popoverClass: 'popover--floating',
          sender: sender
        });
      },
      selectedMoreBtnOption: function selectedMoreBtnOption(option, context) {
        this.send(option, context);
        return false;
      }
    }
  });

  _exports.default = _default;
});