define("mgw/models/user-organization", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    externalId: _emberData.default.attr('string'),
    customer: _emberData.default.belongsTo('customer', {
      inverse: null
    }),
    fullName: Ember.computed('name', 'number', 'country', function () {
      var sufix = '';

      if (this.get('number') || this.get('country')) {
        var countryStr = this.get('country') ? "(".concat(this.get('country'), ")") : '';
        sufix = " \u2022 ".concat(this.get('number'), " ").concat(countryStr);
      }

      return this.get('name') + sufix;
    })
  });

  _exports.default = _default;
});