define("mgw/routes/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    beforeModel: function beforeModel() {
      // If provider has logout uri, redirect to this, else just logout
      if (this.session.data.authenticated && this.session.data.authenticated.provider) {
        var providerInfo = this.ssoproviderservice.getProviderInfo(this.session.data.authenticated.provider);

        if (providerInfo && providerInfo.logoutUri) {
          this.set('session.dontReloadOnInvalidation', true);
          this.session.invalidate().then(function () {
            Ember.run.later(function () {
              return window.location.href = providerInfo.logoutUri + '?redirect_uri=' + window.location.origin;
            }, 3000);
          });
        }
      }

      if (this.session.data.authenticated) {
        this.session.invalidate().then(function () {
          Ember.run.later(function () {
            window.location.href = window.location.origin;
          }, 3000);
        });
      } else {
        window.location.href = window.location.origin;
      }
    }
  });

  _exports.default = _default;
});