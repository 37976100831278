define("mgw/templates/projects-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/8jsegFh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-with-subheader content-white\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header subheader paper-shadow\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"onChange\"],[[30,[36,0],[[32,0],\"changeCustomer\"],null]]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"flex-grow\"],[12],[13],[2,\"\\n    \"],[11,\"button\"],[24,1,\"powerBIBtn\"],[24,0,\"nav-btn\"],[4,[38,0],[[32,0],\"moreBtn\",\"powerBIBtn\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icon icon--powerbi\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"\"],[12],[1,[30,[36,3],[\"exportToPowerBI\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,8],null,[[\"columnSettingsKey\",\"items\",\"classNames\",\"columnSettingsVersion\",\"columns\",\"cache\"],[\"dashboardProjectColumns\",[35,7],\"dashboard-listview\",[35,6],[35,5],[35,4]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"close\",\"export\"],[[30,[36,0],[[32,0],\"closeExportTopics\"],null],[30,[36,0],[[32,0],\"exportToPowerBI\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"export-topics\",\"customer-select\",\"t\",\"cache\",\"columns\",\"columnSettingsVersion\",\"activeCustomerStats\",\"dashboard-grid\",\"showExportTopics\",\"if\"]}",
    "meta": {
      "moduleName": "mgw/templates/projects-list.hbs"
    }
  });

  _exports.default = _default;
});