define("mgw/routes/user-profile", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    afterModel: function afterModel() {
      var _this$controller;

      this.eventservice.sendEvent('opened-profile-edit', {}, 'picg');
      document.cookie = 'first_use=true; path=/';
      (_this$controller = this.controller) === null || _this$controller === void 0 ? void 0 : _this$controller.set('cache', new Date());
    },
    model: function model() {
      return this.get('currentUser.user').reload();
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('initialProfile', JSON.parse(JSON.stringify(model)));
    }
  });

  _exports.default = _default;
});