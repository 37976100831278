define("mgw/templates/components/invite-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BGpvI1iO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"modal modal--letter modal--adjusted-letter\"],[12],[2,\"\\n\\t\"],[10,\"header\"],[14,0,\"bar bar--light flex space-between bar--nav paper-shadow\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn bar__btn\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n\\t\\t\"],[10,\"h1\"],[14,0,\"bar__title\"],[12],[1,[30,[36,2],[\"inviteUser\"],[[\"email\"],[[35,1,[\"id\"]]]]]],[13],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn bar__btn\"],[16,\"disabled\",[34,3]],[4,[38,0],[[32,0],\"submit\"],null],[12],[2,\"OK\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"ul\"],[14,0,\"modal__body flex column flex-grow\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,1,\"form__row__title\"],[14,0,\"form__row form__row--big\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,0,\"form__label flex-grow\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"flex-shrink-0\"],[12],[1,[30,[36,2],[\"project\"],null]],[2,\":\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[10,\"span\"],[14,5,\"margin-right:21px\"],[12],[1,[35,4,[\"name\"]]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[10,\"li\"],[14,1,\"form__row__title\"],[14,0,\"form__row form__row--big\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"label\"],[14,0,\"form__label flex-grow\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[10,\"span\"],[14,0,\"flex-shrink-0\"],[12],[1,[30,[36,2],[\"sendInviteByEmail\"],null]],[2,\":\"],[13],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,6],null,[[\"type\",\"classNames\",\"checked\"],[\"checkbox\",\"form__input--checkbox\",[35,5]]]]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"user\",\"t\",\"disableSubmit\",\"project\",\"sendEmail\",\"input\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/invite-user.hbs"
    }
  });

  _exports.default = _default;
});