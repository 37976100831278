define("mgw/models/project-member", ["exports", "ember-data", "mgw/models/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: _emberData.default.attr('string'),
    privilegeLevel: _emberData.default.attr('number'),
    invited: _emberData.default.attr('boolean', {
      readOnly: true
    }),
    guest: _emberData.default.attr('boolean'),
    ssoProviders: _emberData.default.attr(),
    organization: _emberData.default.attr('string'),
    lastLogin: _emberData.default.attr('date'),
    expirationDate: _emberData.default.attr('date'),
    project: _emberData.default.belongsTo('project'),
    user: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    invitedBy: _emberData.default.belongsTo('user', {
      readOnly: true
    }),
    userOrganization: _emberData.default.belongsTo('userOrganization', {
      inverse: null
    }),
    ssoProvidersString: Ember.computed('ssoProviders', function () {
      if (!this.get('ssoProviders.length')) return '';
      return this.get('ssoProviders').join(', ');
    })
  });

  _exports.default = _default;
});