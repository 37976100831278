define("mgw/templates/components/customer-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Izc2r/Q3",
    "block": "{\"symbols\":[\"customer\"],\"statements\":[[10,\"span\"],[14,0,\"icon--small icon--organization\"],[12],[13],[2,\"\\n\"],[10,\"select\"],[15,\"onchange\",[30,[36,2],[[32,0],\"onSelectedCustomer\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[32,1,[\"id\"]]],[15,\"selected\",[30,[36,1],[[32,1,[\"id\"]],[35,0,[\"id\"]]],null]],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"activeCustomer\",\"eq\",\"action\",\"customers\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/customer-select.hbs"
    }
  });

  _exports.default = _default;
});