define("mgw/templates/components/organizations-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8U3EA7xa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"slick-container\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "mgw/templates/components/organizations-grid.hbs"
    }
  });

  _exports.default = _default;
});