define("mgw/services/current-user", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    nativeservice: Ember.inject.service(),
    idleservice: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    user: null,
    customers: null,
    customerAdminFor: [],
    activeCustomer: null,
    activeCustomerSettingsKey: "activeCustomer",
    promise: Ember.RSVP.resolve(),
    emailCandidateForLogin: '',
    emailToAutofill: Ember.computed.or('user.email', 'emailCandidateForLogin'),
    load: function load() {
      var _this = this;

      if (!this.idleservice.isInitiated) {
        this.idleservice.init();
      }

      if (this.get('session.isAuthenticated') && !this.get('session.data.authenticated.usernamePending')) {
        var promise = Ember.RSVP.hash({
          users: this.store.query('user-profile', {
            filter: {
              profile: true
            }
          }),
          customers: this.store.findAll('customer')
        }).then(function (res) {
          _this.set('user', res.users.get('firstObject'));

          _this.set('customers', res.customers);

          var customerAdminFor = res.customers.filter(function (customer) {
            return _this.get('user.role') == 'rendra_staff' || customer.get('customerAdmins.length') && customer.get('customerAdmins').includes(_this.get('user.id'));
          }).sortBy('name');

          _this.set('customerAdminFor', customerAdminFor);

          if (customerAdminFor.length) {
            _this.set('activeCustomer', customerAdminFor[0]);

            var storedActiveCustomer = _this.get('settings.' + _this.activeCustomerSettingsKey);

            var activeCustomer = storedActiveCustomer && customerAdminFor.findBy('id', storedActiveCustomer);

            if (activeCustomer) {
              _this.set('activeCustomer', activeCustomer);
            } else {
              var _customerAdminFor$;

              _this.set('activeCustomer', customerAdminFor[0]);

              _this.get('settings.' + _this.activeCustomerSettingsKey, (_customerAdminFor$ = customerAdminFor[0]) === null || _customerAdminFor$ === void 0 ? void 0 : _customerAdminFor$.get('id'));
            }
          }

          _this.nativeservice.registerPushNotificationsToken();
        });
        this.set('promise', promise);
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    },
    unload: function unload() {
      var _this2 = this;

      // Wait for earlier loads/unloads, then unload/remove user. Seems very
      // unlikely that this will be an issue, so debatable whether we need to
      // handle race conditions here and make the logic more complicated. But oh
      // well.
      var removeUser = function removeUser() {
        console.info("Unloading user, if they exist");
        var user = _this2.user;

        _this2.set('user', null);

        if (user) {
          _this2.store.unloadRecord(user);
        }
      };

      var promise = this.promise.then(removeUser, removeUser);
      this.set('promise', promise);
      return promise;
    },
    changeActiveCustomer: function changeActiveCustomer(customerId) {
      var customer = this.get('customerAdminFor').findBy('id', customerId);

      if (customer) {
        this.set('activeCustomer', customer);
        this.set('settings.' + this.activeCustomerSettingsKey, customer.get('id'));
      }
    }
  });

  _exports.default = _default;
});