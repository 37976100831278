define("mgw/controllers/mfa-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    error: null,
    actions: {
      submitMFACode: function submitMFACode() {
        var _this = this;

        this.set('error', null);
        var accessToken = this.get('session.data.authenticated.accessToken');
        this.ajax.post("/auth/v1/mfa/enable", {
          data: {
            accessToken: accessToken,
            code: this.code
          }
        }).then(function () {
          _this.router.transitionTo('index');
        }).catch(function (err) {
          _this.set('error', err);

          console.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});