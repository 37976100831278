define("mgw/components/delete-user-info", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    intercom: Ember.inject.service(),
    users: Ember.computed.alias('data'),
    usersEmails: Ember.computed('users', function () {
      return this.get('users').mapBy('email').join(', ');
    }),
    contactSupport: function contactSupport() {
      var subject = this.intl.t('requestToDeleteUser');
      var body = this.intl.t('requestToDeleteUsers__description', {
        emails: this.get('userEmails'),
        name: this.get('user.displayName')
      });

      if (this.intercom.isBooted) {
        this.intercom.showNewMessage(body);
      } else {
        var a = document.createElement('a');
        a.href = 'mailto:support@streambim.com?subject=' + subject + '&body=' + body;
        a.click();
      }
    },
    actions: {
      submit: function submit() {
        this.contactSupport();
        this.send('close');
      },
      cancel: function cancel() {
        this.send('close');
      }
    }
  });

  _exports.default = _default;
});