define("mgw/models/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    _pinnedRelationships: null,
    type: Ember.computed('constructor.modelName', function () {
      return Ember.String.camelize(this.get('constructor.modelName'));
    }),
    save: function save(options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this._super(options).then(function (result) {
          return resolve(result);
        }).catch(function (error) {
          return _this.reload().finally(function () {
            _this.rollbackAttributes();

            return reject(error);
          });
        });
      });
    },
    pinRelationships: function pinRelationships() {
      var _this2 = this;

      var pinnedRelationships = {};
      this.eachRelationship(function (name, descriptor) {
        var _this2$get, _this2$get2;

        switch (descriptor.kind) {
          case 'hasMany':
            return pinnedRelationships[name] = _this2.get(name), function (x) {
              return x.toArray() || [];
            };

          case 'belongsTo':
            return pinnedRelationships[name] = ((_this2$get = _this2.get(name)) === null || _this2$get === void 0 ? void 0 : _this2$get.get('id')) ? ((_this2$get2 = _this2.get(name)) === null || _this2$get2 === void 0 ? void 0 : _this2$get2.content) || _this2.get(name) || null : null;
        }
      });
      return this.set('_pinnedRelationships', pinnedRelationships);
    },
    hasDirtyAttributesOrRelationships: function hasDirtyAttributesOrRelationships() {
      var _this3 = this;

      if (this.get('hasDirtyAttributes')) return true;
      if (!this.get('_pinnedRelationships')) return false;
      var hasDirtyElements = false;
      this.eachRelationship(function (name) {
        var pinnedValue = _this3.get('_pinnedRelationships')[name];

        if (pinnedValue === null || pinnedValue === void 0 ? void 0 : pinnedValue.length) {
          var _this3$get;

          var newIds = (_this3$get = _this3.get(name)) === null || _this3$get === void 0 ? void 0 : _this3$get.mapBy('id').sort();
          var pinnedIds = pinnedValue === null || pinnedValue === void 0 ? void 0 : pinnedValue.mapBy('id').sort();

          if (newIds.length !== pinnedIds.length) {
            hasDirtyElements = true;
            return;
          }

          var diff = false;
          var i = 0;

          while (i < newIds.length && !diff) {
            diff = newIds[i] !== pinnedIds[i];
            i++;
          }

          if (diff) {
            hasDirtyElements = true;
            return;
          }
        } else {
          var _this3$get2;

          if (!(((_this3$get2 = _this3.get(name)) === null || _this3$get2 === void 0 ? void 0 : _this3$get2.get('id')) === (pinnedValue === null || pinnedValue === void 0 ? void 0 : pinnedValue.get('id')))) {
            hasDirtyElements = true;
            return;
          }
        }
      });
      return hasDirtyElements;
    },
    rollbackRelationships: function rollbackRelationships() {
      var _this4 = this;

      if (!this._pinnedRelationships) {
        return;
      }

      this.eachRelationship(function (name, descriptor) {
        if (descriptor.kind === 'hasMany') {
          return _this4.get(name).setObjects(_this4.get("_pinnedRelationships.".concat(name)).toArray() || []);
        }

        return _this4.set(name, _this4.get("_pinnedRelationships.".concat(name)) || null);
      });
    },
    rollbackAttributes: function rollbackAttributes() {
      this.rollbackRelationships();
      return this._super();
    },
    didCreate: function didCreate() {
      this.pinRelationships();
      return this._super();
    },
    didUpdate: function didUpdate() {
      this.pinRelationships();
      return this._super();
    }
  });

  _exports.default = _default;
});