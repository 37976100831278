define("mgw/components/navigation-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['multiOption:nav-dropdown'],
    route: null,
    icon: null,
    text: '',
    options: null,
    hideTextOnSmallScreens: true,
    multiOption: Ember.computed.alias('options.length'),
    actions: {
      onClick: function onClick() {
        if (this.onSelect) {
          this.onSelect(this.route);
        }

        return false;
      },
      onSelectedOption: function onSelectedOption(route) {
        if (this.onSelect) {
          this.onSelect(route);
        }

        return false;
      }
    }
  });

  _exports.default = _default;
});