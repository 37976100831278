define("mgw/components/invite-user", ["exports", "mgw/components/popover-base", "mgw/misc/utils"], function (_exports, _popoverBase, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    user: Ember.computed.alias('data.user'),
    project: Ember.computed.alias('data.project'),
    sendEmail: true,
    disableSubmit: false,
    sendInvite: function sendInvite(resolve, reject) {
      var _this = this;

      var body = {
        userID: this.get('user.id')
      };
      var accessToken = this.get('session.data.authenticated.idToken');
      var req = new XMLHttpRequest();

      try {
        req.open('POST', '/mgw/api/v1/projects/' + this.get('project.id') + '/invites', false);
        req.setRequestHeader('Authorization', 'Bearer ' + accessToken);
        req.setRequestHeader('Content-Type', 'application/json');

        req.onload = function () {
          if (req.status == 200) {
            _this.store.findRecord('project-member', _this.get('project.id') + '-' + _this.get('user.id')).then(function (record) {
              return resolve(record);
            }).catch(function (err) {
              return reject(err);
            });
          } else {
            reject(req.statusText);
          }
        };

        req.onerror = function () {
          reject(req.statusText);
        };

        req.send(JSON.stringify(body));
      } catch (err) {
        reject(err);
      }
    },
    actions: {
      cancel: function cancel() {
        this.send('close');
      },
      submit: function submit() {
        var _this2 = this;

        var twoDaysFromNow = new Date();
        twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
        var promise = null;
        this.get('eventservice').sendEvent('invited-user');

        if (this.get('sendEmail')) {
          promise = new Ember.RSVP.Promise(function (resolve, reject) {
            _this2.sendInvite(resolve, reject);
          });
        } else {
          var invite = this.store.createRecord('projectMember', {
            user: this.get('user'),
            project: this.get('project'),
            privilegeLevel: _utils.default.privilegeLevels.member,
            // user
            ssoProviders: []
          });
          promise = invite.save();
        }

        this.set('disableSubmit', true);
        promise.then(function (record) {
          _this2.send('close');

          _this2.get('delegate').send(_this2.get('callback'), record, _this2.options.context);

          Ember.run.later(function () {
            alert(_this2.intl.t("invitationSentSuccessfully"));
          }, 100);
        }).catch(function (error) {
          if (!_this2.isDestroyed) {
            alert(error);

            _this2.set('disableSubmit', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});