define("mgw/routes/mfa-setup", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var accessToken = this.get('session.data.authenticated.accessToken');
      var modelHash = {
        mfadata: this.ajax.post('/auth/v1/mfa/associate', {
          data: {
            accessToken: accessToken
          }
        })
      };
      return Ember.RSVP.hash(modelHash).catch(function (err) {
        console.error(err);

        _this.session.invalidate();
      });
    }
  });

  _exports.default = _default;
});