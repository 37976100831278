define("mgw/authenticators/cognito-staff", ["exports", "mgw/authenticators/cognito-mfa"], function (_exports, _cognitoMfa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cognitoMfa.default.extend({
    refreshAccessTokens: false,
    authenticate: function authenticate(data) {
      return this.cognito.completeMFAChallenge(data).then(function (res) {
        delete res.refreshToken;
        return res;
      });
    }
  });

  _exports.default = _default;
});