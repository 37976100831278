define("mgw/templates/components/delete-user-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJghscyP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"modal modal--letter modal--adjusted-letter\"],[12],[2,\"\\n\\t\"],[10,\"header\"],[14,0,\"bar bar--light flex space-between bar--nav paper-shadow\"],[12],[2,\"\\n\\t\\t\"],[11,\"button\"],[24,0,\"btn bar__btn\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[1,[30,[36,1],[\"cancel\"],null]],[13],[2,\"\\n\\t\\t\"],[10,\"h1\"],[14,0,\"bar__title\"],[12],[1,[30,[36,1],[\"deleteUser\"],null]],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mgw__content align-items-center\"],[14,5,\"margin: 40px auto\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mgw__logo logo icon--delete\"],[14,5,\"margin: 0\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-align-center margin-bottom-11 margin-top-22\"],[12],[1,[30,[36,1],[\"deleteOtherUsers__description\"],null]],[2,\": \"],[10,\"span\"],[14,0,\"mgw__subheader\"],[12],[1,[34,2]],[13],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,0],[[32,0],\"submit\"],null],[12],[1,[30,[36,1],[\"contactSupport\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"usersEmails\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/delete-user-info.hbs"
    }
  });

  _exports.default = _default;
});