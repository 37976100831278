define("mgw/templates/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "unMRI1M4",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"option\"],[14,\"disabled\",\"\"],[15,\"selected\",[30,[36,8],[[35,7]],null]],[12],[2,\"\\n    \"],[1,[34,9]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,11]],null]],null]],[[\"key\"],[\"@identity\"]],[[\"default\"],[{\"statements\":[[6,[37,6],[[32,1,[\"inactive\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[31,[[30,[36,1],[[32,1],[35,0]],null]]]],[15,\"selected\",[30,[36,4],[[30,[36,3],[[30,[36,1],[[32,1],[35,0]],null],\"\"],null],[35,2]],null]],[15,\"disabled\",[32,1,[\"disabled\"]]],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1],[35,5]],null]],[2,\"\\n     \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optionValuePath\",\"read-path-helper\",\"_value\",\"concat\",\"eq\",\"optionLabelPath\",\"unless\",\"value\",\"not\",\"prompt\",\"if\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/select-dropdown.hbs"
    }
  });

  _exports.default = _default;
});