define("mgw/controllers/mfa-challenge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    eventservice: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    passwordservice: Ember.inject.service(),
    queryParams: ['username', 'sessiondata'],
    username: null,
    sessiondata: null,
    response: null,
    isPerformingRequest: false,
    error: null,
    // code, session
    actions: {
      submitMFACode: function submitMFACode() {
        var _this = this;

        var authenticator;

        if (this.username.indexOf('@rendra.io') != -1) {
          authenticator = 'authenticator:cognito-staff';
        } else {
          authenticator = 'authenticator:cognito-mfa';
        }

        this.session.authenticate(authenticator, {
          code: this.code,
          username: this.username,
          session: this.sessiondata
        }).then(function () {
          _this.router.transitionTo('index');
        }).catch(function (err) {
          if (err.payload.error === "invalidCode") {
            _this.set('error', 'code');
          } else {
            _this.set('error', 'session');
          }
        });
      }
    }
  });

  _exports.default = _default;
});