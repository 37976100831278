define("mgw/templates/components/profile-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aObctsho",
    "block": "{\"symbols\":[\"organization\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"power-select\",[],[[\"@options\",\"@renderInPlace\",\"@placeholder\",\"@selected\",\"@searchField\",\"@searchEnabled\",\"@search\",\"@onChange\",\"@class\",\"@disabled\",\"@allowClear\",\"@triggerClass\"],[[34,4],false,[34,1],[34,2],\"name\",true,[30,[36,0],[[32,0],\"queryOrgs\"],null],[30,[36,0],[[32,0],\"onChangedOption\"],null],\"ember-power-select--labels input-row__input__input\",false,false,[31,[\"form__input form__input--row profile--field-selector \",[30,[36,5],[[35,2],\"with-x-btn\"],null]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"flex space-between align-items-center width-100\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[1,[32,1,[\"fullName\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"icon icon--organization \",[30,[36,6],[[32,1,[\"type\"]],\" hidden\"],null]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"type\",\"class\",\"value\",\"placeholder\"],[\"text\",\"form__input two-btns form__input--row\",[35,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[15,0,[31,[\"profile--field-icon icon \",[34,7]]]],[12],[13],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"profile--field-delete-btn icon--small icon--x\"],[4,[38,0],[[32,0],\"deleteField\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"_placeholder\",\"_value\",\"input\",\"organizations\",\"if\",\"unless\",\"iconName\"]}",
    "meta": {
      "moduleName": "mgw/templates/components/profile-input.hbs"
    }
  });

  _exports.default = _default;
});