define("mgw/components/organizations-grid", ["exports", "ember-local-storage", "mgw/misc/utils"], function (_exports, _emberLocalStorage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Slick = window.Slick;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: (0, _emberLocalStorage.storageFor)('settings'),
    popoverservice: Ember.inject.service(),
    tagName: "ul",
    columnSettingsVersion: 1,
    // input
    columnSettingsKey: 'organizationsGridColumns',
    items: null,
    columns: [],
    cache: null,
    searchStr: '',
    sortByCol: Ember.computed("cache", function () {
      var storedColumn = this.get('settings.' + this.columnSettingsKey + 'SortByCol');
      return storedColumn ? storedColumn : {
        field: 'id'
      };
    }),
    sortIsAsc: Ember.computed("cache", function () {
      var sortIsAsc = this.get('settings.' + this.columnSettingsKey + 'SortIsAsc');
      return sortIsAsc ? true : false;
    }),
    sortedItems: Ember.computed("items", "sortByCol", "sortIsAsc", "cache", function () {
      var _this = this;

      var sort = this.items.sort(function (a, b) {
        var _a$users, _b$users;

        var value;
        if (_this.get('sortByCol') === "users") value = (((_a$users = a.users) === null || _a$users === void 0 ? void 0 : _a$users.length) || 0) - (((_b$users = b.users) === null || _b$users === void 0 ? void 0 : _b$users.length) || 0);else value = (a.organization.get(_this.sortByCol.field) || '').localeCompare(b.organization.get(_this.sortByCol.field) || '');
        return value || a.organization.name.localeCompare(b.organization.name); // If same, we just compare by id
      });
      if (this.sortIsAsc) return sort;
      return sort.reverse();
    }),
    observeItems: Ember.observer("items", function () {
      this.refreshGrid();
    }),
    observeCache: Ember.observer("cache", "searchStr", function () {
      Ember.run.debounce(this, this.refreshGrid, 300);
    }),
    dataView: null,
    grid: null,
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
      window.enableJqueryDrag();
    },
    defaultFormatter: function defaultFormatter(row, cell, value, columnDef, dataContext) {
      if (columnDef.field === "users") {
        var _dataContext$columnDe;

        return ((_dataContext$columnDe = dataContext[columnDef.field]) === null || _dataContext$columnDe === void 0 ? void 0 : _dataContext$columnDe.length) || 0;
      } else {
        return Ember.Handlebars.Utils.escapeExpression(dataContext.organization.get(columnDef.field) || '');
      }
    },
    defaultFilter: function defaultFilter(item) {
      if (!this.searchStr || !this.searchStr.length) return true;

      var _iterator = _createForOfIteratorHelper(this.columns),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var column = _step.value;
          if (column.field == "users") continue;
          if (column.field && _utils.default.isMatch(item[column.field], this.searchStr)) return true;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return false;
    },
    refreshGrid: function refreshGrid() {
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.endUpdate();
      this.grid.invalidateAllRows();
      this.grid.setSelectedRows([]);
      if (this.didSelectItem) this.didSelectItem(null);
      this.grid.render();
    },
    saveColumnSettings: function saveColumnSettings() {
      this.set('settings.' + this.columnSettingsKey, this.get("grid").getColumns());
      this.set('settings.' + this.columnSettingsKey + 'Version', this.columnSettingsVersion);
    },
    setup: function setup() {
      var _this2 = this;

      var options = {
        enableCellNavigation: true,
        explicitInitialization: true,
        enableColumnReorder: true,
        defaultFormatter: this.defaultFormatter,
        forceSyncScrolling: true,
        defaultColumnWidth: 100,
        enableAsyncPostRender: true,
        asyncPostRenderDelay: 50,
        fullWidthRows: true,
        forceFitColumns: true,
        rowHeight: 40
      };
      this.set("dataView", new Slick.Data.DataView());
      this.set("grid", new Slick.Grid("#".concat(this.elementId, " .slick-container"), this.dataView, [], options));
      this.grid.setSortColumn(this.sortByCol.field, this.sortIsAsc);
      this.grid.onCellChange.subscribe(function (e, args) {
        _this2.dataView.updateItem(args.item.id, args.item);
      });
      this.dataView.onRowCountChanged.subscribe(function () {
        _this2.grid.updateRowCount();

        _this2.grid.render();
      });
      this.dataView.onRowsChanged.subscribe(function (e, args) {
        _this2.grid.invalidateRows(args.rows);

        _this2.grid.render();
      });
      this.grid.onColumnsResized.subscribe(function () {
        _this2.saveColumnSettings();
      });
      this.grid.onColumnsReordered.subscribe(function () {
        _this2.saveColumnSettings();
      });
      this.grid.onSort.subscribe(function (e, args) {
        _this2.set('settings.' + _this2.columnSettingsKey + 'SortByCol', args.sortCol);

        _this2.set('settings.' + _this2.columnSettingsKey + 'SortIsAsc', args.sortAsc);

        _this2.set('cache', new Date());
      });
      this.grid.onClick.subscribe(function (e, args) {
        _this2.grid.setSelectedRows([args.row]);

        var item = _this2.get('dataView').getItem(args.row);

        if (_this2.didSelectItem) _this2.didSelectItem(item);
      });
      this.grid.onDblClick.subscribe(function (e, args) {
        var dataView = args.grid.getData();
        var item = dataView.getItem(args.row);
        if (_this2.editOrganization) _this2.editOrganization(item);
        return;
      });
      var rowSelectionModel = new Slick.RowSelectionModel();
      this.set('rowSelectionModel', rowSelectionModel);
      this.get('grid').setSelectionModel(rowSelectionModel);
      this.grid.init();
      this.grid.setColumns(this.columns);
      this.dataView.beginUpdate();
      this.dataView.setItems(this.sortedItems.toArray());
      this.dataView.setFilter(this.defaultFilter.bind(this));
      this.dataView.setFilterArgs({
        searchStr: this.get('searchStr')
      });
      this.dataView.endUpdate();
      this.grid.resizeCanvas();
    },
    willDestroyElement: function willDestroyElement() {
      window.disableJqueryDrag();
      this.grid.destroy();
      this.set("grid", null);
      this.set("dataView", null);
    }
  });

  _exports.default = _default;
});