define("mgw/routes/federation-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ssoproviderservice: Ember.inject.service(),
    cognito: Ember.inject.service(),
    initialStep: null,
    steps: {
      waitingForAction: 'WAITING_FOR_ACTION',
      linkingToExistingUser: 'LINKING_TO_EXISTING_USER',
      linkingToExistingUserSuccess: 'LINKING_TO_EXISTING_USER_SUCCESS',
      linkingToExistingUserFailed: 'LINKING_TO_EXISTING_USER_FAILED',
      creatingNewUser: 'CREATING_NEW_USER',
      creatingNewUserFailed: 'CREATING_NEW_USER_FAILED'
    },

    /* eslint-disable max-len */

    /* eslint-disable max-nested-callbacks */
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var params = transition.to.queryParams || {};
      this.set('provider', params.state);
      this.set('initialStep', this.steps.waitingForAction);
      return new Ember.RSVP.Promise(function (resolve) {
        var _this$session$data, _this$session$data$au, _this$currentUser$use, _this$session$data2, _this$session$data2$a, _this$currentUser$use2;

        var alreadyLoggedInWithCognitoAs = ((_this$session$data = _this.session.data) === null || _this$session$data === void 0 ? void 0 : (_this$session$data$au = _this$session$data.authenticated) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.authenticator) == "authenticator:cognito" && ((_this$currentUser$use = _this.currentUser.user) === null || _this$currentUser$use === void 0 ? void 0 : _this$currentUser$use.id);
        var alreadyLoggedInWithFederationAs = ((_this$session$data2 = _this.session.data) === null || _this$session$data2 === void 0 ? void 0 : (_this$session$data2$a = _this$session$data2.authenticated) === null || _this$session$data2$a === void 0 ? void 0 : _this$session$data2$a.authenticator) == "authenticator:cognito-federation" && ((_this$currentUser$use2 = _this.currentUser.user) === null || _this$currentUser$use2 === void 0 ? void 0 : _this$currentUser$use2.id);

        _this.session.authenticate('authenticator:cognito-federation', params.code, params.state).then(function () {
          if (alreadyLoggedInWithCognitoAs) {
            // Existing StreamBIM user linked to external identity
            _this.set('initialStep', _this.steps.linkingToExistingUserSuccess);

            resolve();
          } else if (alreadyLoggedInWithFederationAs) {
            _this.currentUser.load().then(function () {
              resolve(_this.transitionTo('index')); // TODO: next parameter
            });
          } else {
            resolve(_this.transitionTo('index')); // TODO: next parameter
          }
        }).catch(function (err) {
          if (err.status == 449) {
            // User needs to confirm unknown email
            _this.session.authenticate('authenticator:cognito-federation-existing', err.payload.RefreshToken, params.state).then(function () {
              if (err.payload.ProposedUsername) {
                var proposedUsername = err.payload.ProposedUsername;

                var providerInfo = _this.get('ssoproviderservice').getProviderInfo(_this.get('provider'));

                if (providerInfo.disallowLinking) {
                  // There is no federation record for the email, and linking is disabled.
                  // First, try to create new user
                  _this.cognito.createFederated(_this.provider, _this.session.data.authenticated.idToken, proposedUsername).then(function () {
                    _this.set('session.data.authenticated.usernamePending', false);

                    var authInLocalStorage = JSON.parse(window.localStorage.getItem('ember_simple_auth-session'));
                    delete authInLocalStorage.authenticated.usernamePending;
                    window.localStorage.setItem('ember_simple_auth-session', JSON.stringify(authInLocalStorage));

                    _this.currentUser.load().then(function () {
                      _this.transitionTo('user-profile');
                    });
                  }).catch(function (errV2) {
                    if (errV2.status == 409) {
                      // User exists, so we need to link to the existing user, which requires the user to type the password
                      _this.set('initialStep', _this.steps.linkingToExistingUser);

                      _this.set('proposedUsername', proposedUsername);
                    } else {
                      var _errV2$responseJSON;

                      _this.set('error', (errV2 === null || errV2 === void 0 ? void 0 : (_errV2$responseJSON = errV2.responseJSON) === null || _errV2$responseJSON === void 0 ? void 0 : _errV2$responseJSON.error) || JSON.stringify((errV2 === null || errV2 === void 0 ? void 0 : errV2.responseJSON) || errV2 || 'unknown error'));
                    }

                    resolve();
                  });
                } else {
                  _this.set('proposedUsername', proposedUsername);

                  resolve();
                }
              } else {
                _this.set('initialStep', _this.steps.creatingNewUser);

                resolve();
              }
            });
          } else {
            // Other error
            _this.set('error', err);

            resolve();
          }
        });
      });
    },

    /* eslint-enable max-nested-callbacks */

    /* eslint-enable max-len */
    setupController: function setupController(controller) {
      controller.set('error', this.error);
      controller.set('email', this.proposedUsername);
      controller.set('initialProposedUsername', this.proposedUsername);
      controller.set('provider', this.provider);
      controller.set('currentStep', this.initialStep);
      controller.set('steps', this.steps);

      this._super(controller);
    }
  });

  _exports.default = _default;
});