define("mgw/controllers/mfa-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      confirmRemoval: function confirmRemoval() {
        var _this = this;

        var accessToken = this.get('session.data.authenticated.accessToken');
        this.ajax.post("/auth/v1/mfa/disable", {
          data: {
            accessToken: accessToken
          }
        }).then(function () {
          _this.router.transitionTo('index');
        }).catch(function (err) {
          return console.log(err);
        });
      }
    }
  });

  _exports.default = _default;
});