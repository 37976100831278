define("mgw/services/powerbiservice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    generatePowerBICodeToGetAccessToken: function generatePowerBICodeToGetAccessToken(refreshToken) {
      var tokenRefreshUrl = window.location.origin + '/mgw/api/v2/refresh';

      if (!refreshToken) {
        alert('You need to be logged in with "Keep me logged in" checked to export to Power BI');
        throw new Error();
      }

      return "\n      RefreshToken = \"".concat(refreshToken, "\",\n      TokenRefreshUrl = \"").concat(tokenRefreshUrl, "\",\n      Payload = \"{\"\"refreshToken\"\": \"\"\" & RefreshToken & \"\"\"}\",\n      PayloadBinary = Text.ToBinary(Payload),\n      TokenResponse = Json.Document(Web.Contents(TokenRefreshUrl,\n        [\n          Headers = [\n            #\"accept\" = \"application/json\",\n            #\"content-type\" = \"application/json\"\n          ],\n          Content = PayloadBinary\n        ]\n      )),\n      AccessToken = TokenResponse[idToken],\n    ");
    }
  });

  _exports.default = _default;
});