define("mgw/components/edit-user-organization", ["exports", "mgw/components/popover-base"], function (_exports, _popoverBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _popoverBase.default.extend({
    intl: Ember.inject.service(),
    // Input
    organization: Ember.computed.alias('data.organization'),
    organizations: Ember.computed.alias('data.organizations'),
    users: Ember.computed.alias('data.users'),
    isNew: Ember.computed.not('organization.id'),
    confirmDelete: false,
    duplicatedName: Ember.computed('organizations', 'organization.name', function () {
      var _this = this;

      if (!this.get('organization.name.length')) return false;
      return this.get('organizations').find(function (org) {
        var _this$get, _org$get;

        if (((_this$get = _this.get('organization.name')) === null || _this$get === void 0 ? void 0 : _this$get.toLowerCase()) == (((_org$get = org.get('name')) === null || _org$get === void 0 ? void 0 : _org$get.toLowerCase()) || "") && _this.get('organization.id') !== org.get('id')) return true;
        return false;
      });
    }),
    validName: Ember.computed('organization.name', function () {
      if (!this.get('organization.name.length') || this.get('duplicatedName')) return false;
      return true;
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce("afterRender", this, this.setup);
    },
    setup: function setup() {
      if (!this.get('isNew')) this.get('organization').pinRelationships();
    },
    actions: {
      submit: function submit() {
        var _this2 = this;

        this.set('loading', true);
        var newOrg = this.get('isNew');
        this.get('organization').save().then(function () {
          if (_this2.get('callback')) _this2.get('delegate').send(_this2.get('callback'), newOrg);

          _this2.set('loading', false);

          _this2.send('close');

          return false;
        }).catch(function (err) {
          console.warn('Error updating organization: ' + err);

          _this2.set('loading', false);
        });
      },
      cancel: function cancel() {
        if (this.get('isNew')) {
          this.get('organization').deleteRecord();
        } else {
          this.get('organization').rollbackAttributes();
        }

        this.send('close');
        return false;
      },
      deleteOrganization: function deleteOrganization() {
        this.set('confirmDelete', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('confirmDelete', false);
      },
      confirmDelete: function confirmDelete() {
        var _this3 = this;

        this.set('confirmDelete', false);
        this.set('loading', true);
        this.get('organization').destroyRecord().then(function () {
          if (_this3.get('callback')) _this3.get('delegate').send(_this3.get('callback'), true);

          _this3.set('loading', false);

          _this3.send('close');
        }).catch(function (err) {
          console.warn('Error deleting organization: ' + err);

          _this3.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});